.toggle-switch {
    display: flex;
    align-items: flex-start;
    margin-top: 3px;
    margin-bottom: 20px;

    &__inner {
        position: relative;
        flex-shrink: 0;
        width: 64px;
        height: 32px;
        margin-top: -3px;
        margin-right: 19px;
    }

    &__input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .2s;
        border-radius: 16px;
        border: 1px solid #cccccc;

        &::before {
            position: absolute;
            content: "";
            height: 22px;
            width: 22px;
            left: 5px;
            bottom: 3px;
            background-color: white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            border: solid 1px #cccccc;
            transition: .2s;
            border-radius: 50%;
        }
    }

    &__input:checked + &__slider {
        background-color: $brand__primary__color;
        border-color: $brand__primary__color;
    }

    &__input:focus + &__slider {
        box-shadow: 0 0 1px $brand__primary__color;
    }

    &__input:checked + &__slider:before {
        transform: translateX(29px);
    }
}
