.transition-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 200ms, opacity 200ms;
}

.transition-visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 200ms;
}
